import {
  MODAL_BAJA_CATE,
  MODAL_BAJA_CURSO,
  MODAL_BAJA_PROFE,
  SET_FLAG_JWT,
} from "../../types/index.js";

const adminReducer = (state, action) => {
  switch (action.type) {
    case MODAL_BAJA_CATE:
      return {
        modalBajaCate: action.payload,
      };
    case MODAL_BAJA_CURSO:
      return {
        modalBajaCurso: action.payload,
      };
    case MODAL_BAJA_PROFE:
      return {
        modalBajaProfe: action.payload,
      };
    case SET_FLAG_JWT:
      return {
        JWT_Flag: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
