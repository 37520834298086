import React, { useReducer } from "react";
import adminContext from "./adminContext";
import adminReducer from "./adminReducer";

import {
  MODAL_BAJA_CATE,
  MODAL_BAJA_CURSO,
  MODAL_BAJA_PROFE,
  SET_FLAG_JWT,
} from "../../types/index.js";

const AdminState = (props) => {
  const initialState = {
    modalBajaCate: false,
    modalBajaCurso: false,
    modalBajaProfe: false,

    JWT_Flag: null,
  };

  const [state, dispatch] = useReducer(adminReducer, initialState);

  const setModalBajaCate = (modalState) => {
    dispatch({
      type: MODAL_BAJA_CATE,
      payload: modalState,
    });
  };

  const setModalBajaCurso = (modalState) => {
    dispatch({
      type: MODAL_BAJA_CURSO,
      payload: modalState,
    });
  };

  const setModalBajaProfesor = (modalState) => {
    dispatch({
      type: MODAL_BAJA_PROFE,
      payload: modalState,
    });
  };

  const setJWTFlag = (flagState) => {
    dispatch({
      type: SET_FLAG_JWT,
      payload: flagState,
    });
  };

  const formatDate = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        (day[1] ? day : "0" + day[0]) +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        date.getFullYear();
      return fecha;
    }
  };

  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  const checkJWT = (flagState) => {
    // aca deberia pegarle al endpoint de Self (cuando esté)
    if (localStorage.getItem("token")) {
      setJWTFlag(flagState);
    } else {
      window.location.href = "/auth/login";
    }
  };

  const log_out = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  };

  return (
    <adminContext.Provider
      value={{
        modalBajaCate: state.modalBajaCate,
        modalBajaCurso: state.modalBajaCurso,
        modalBajaProfe: state.modalBajaProfe,
        JWT_Flag: state.JWT_Flag,
        setModalBajaCate,
        setModalBajaCurso,
        setModalBajaProfesor,
        formatDate,
        formatImporte,
        log_out,
        checkJWT,
        setJWTFlag,
      }}
    >
      {props.children}
    </adminContext.Provider>
  );
};

export default AdminState;
