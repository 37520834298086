import React from "react";

import ReactImageDot from "./ReactImageDot";
import micro from "../../assets/img/micro.png";

/**
 * Example component used to wrap React-Image-Dot. Since the dots are passed in
 * via props, you are free to handle the logic behind the dots as you see fit.
 */
export default class ReactDotWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.initialDots =
      this.props.puntosIniciales === undefined
        ? []
        : this.props.puntosIniciales.map((p) => JSON.parse(p));
    this.props.setPuntos(this.props.puntosIniciales);
    this.state = {
      dots: this.initialDots,
      color: "blue",
    };
  }

  addDot = (dot) => {
    this.setState({
      dots: [...this.state.dots, dot],
    });
    let aux = [...this.state.dots, dot].map((d) => {
      return JSON.stringify({ x: d.x, y: d.y, color: d.color });
    });
    this.props.setPuntos(aux);
  };

  deleteDot = (index) => {
    this.setState({
      dots: this.state.dots.filter((_, i) => {
        return i !== index;
      }),
    });
  };

  resetDots = () => {
    this.setState({
      dots: this.initialDots,
    });
    this.props.setPuntos(this.initialDots);
  };

  changeBlue = () => {
    this.setState({
      color: "blue",
    });
  };

  changeRed = () => {
    this.setState({
      color: "red",
    });
  };

  render() {
    const { dots, color } = this.state;

    return (
      <div className="flex flex-col justify-center">
        <ReactImageDot
          backgroundImageUrl={micro}
          width={320}
          height={340}
          dots={dots}
          deleteDot={this.deleteDot}
          addDot={this.addDot}
          dotRadius={6}
          color={color}
          dotStyles={{
            boxShadow: "0 2px 4px gray",
          }}
          backgroundSize={"cover"}
        />
        <p className="text-center">Seleccione para marcar en la imagen</p>
        <div className="flex flex-col justify-center items-center gap-2 ">
          <div className="flex flex-row gap-2 justify-center w-full">
            <button
              className={`rounded-xl border-2 border-black py-2 px-1 w-1/2 lg:w-2/5 ${
                color === "red" ? "text-white bg-rose-500" : "text-black"
              }`}
              onClick={this.changeRed}
            >
              Choques
            </button>

            <button
              className={`rounded-xl py-2 px-1 w-1/2 lg:w-2/5  border-2 border-black ${
                color === "blue" ? "text-white bg-blue-400" : "text-black"
              }`}
              onClick={this.changeBlue}
            >
              Dispositivo
            </button>
          </div>
          <button
            className="w-full lg:w-4/5 rounded-xl border-2 border-black py-2 px-1  active:bg-yellow-500"
            onClick={this.resetDots}
          >
            Reiniciar
          </button>
        </div>
      </div>
    );
  }
}
