import React from "react";

export default function ModalBaja({
  titulo,
  descripcion,
  funcionCierre,
  funcionAccion,
  loadingState,
}) {
  const descripcionParsed = descripcion.split("||");
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        //onClick={() => funcionCierre()}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">{titulo}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => funcionCierre()}
              >
                ×
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                {descripcionParsed[0]} <b>{descripcionParsed[1]}</b>{" "}
                {descripcionParsed[2]}
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-between p-4 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className={`${
                  !loadingState ? "bg-red-500" : "bg-gray-500"
                } text-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ${
                  !loadingState ? "hover:bg-red-600" : ""
                } ${!loadingState ? "transition duration-300" : ""} ${
                  loadingState ? "cursor-not-allowed" : ""
                }`}
                type="button"
                disabled={loadingState}
                onClick={() => funcionCierre()}
              >
                Cancelar
              </button>
              <button
                className={`${
                  !loadingState ? "bg-emerald-500" : "bg-gray-500"
                } text-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ${
                  !loadingState ? "hover:bg-emerald-600" : ""
                } ${!loadingState ? "transition duration-300" : ""} ${
                  loadingState ? "cursor-not-allowed" : ""
                }`}
                type="button"
                disabled={loadingState}
                onClick={() => funcionAccion()}
              >
                {!loadingState ? "Confirmar" : "Procesando"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
