import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../config/axios";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";
import sonda_nacion from "../../assets/img/sonda_nacion.png";
import {
  pdf,
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function TableRegistro() {
  const adminContext = useContext(AdminContext);
  const { modalBajaCate, setModalBajaCate, checkJWT } = adminContext;
  const [datos, setDatos] = useState([]);
  const [loadingRegistros, setLoadingRegistros] = useState(true);
  const [datosModal, setDatosModal] = useState(null);
  const [empresa, setEmpresa] = useState("0");
  const [loadingPdf, setLoadingPdf] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [empresaFinal, setEmpresaFinal] = useState({});
  const [operador, setOperador] = useState(
    localStorage.getItem("role") == "False"
      ? localStorage.getItem("user_id")
      : 0
  );
  const [patente, setPatente] = useState("");
  const [operadores, setOperadores] = useState([]);
  const [linea, setLinea] = useState("0");
  const [lineas, setLineas] = useState([]);
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [datosAlert, setDatosAlert] = useState({
    bgColor: "green-500",
    message: "",
    show: false,
  });
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 25;

  const [exportDatos, setExportDatos] = useState([]);
  const [exportDatosPdf, setExportDatosPdf] = useState([]);

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);
  const getTodayFormated = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + "/" + mm + "/" + yyyy;
    return today;
  };

  /* PDF Template */
  let pdfTemplate = (
    <Document file="pdf-registros.pdf">
      {exportDatosPdf.map((each) => (
        <Page size="A4" style={{ height: "100vh", width: "100%", padding: 10 }}>
          <View
            fixed
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "40%" }}>
              <Text style={{ fontSize: "10", paddingVertical: 2 }}>
                Licitacion Publica: {getTodayFormated().slice(3)}
              </Text>
              <Text style={{ fontSize: "10", paddingVertical: 2 }}>
                Acta N°. ??? Parcial
              </Text>
              <Text style={{ fontSize: "10", paddingVertical: 2 }}>
                Fecha: {getTodayFormated()}
              </Text>
              <Text
                style={{
                  fontSize: "12",
                  fontWeight: "bold",
                  paddingVertical: 2,
                  paddingBottom: 10,
                }}
              >
                Empresa: {empresaFinal.nombre}
              </Text>
            </View>
            <View style={{ width: "40%" }}>
              <Image src={sonda_nacion} />
            </View>
          </View>
          <View style={{ backgroundColor: "#005da1", textAlign: "center" }}>
            <Text style={{ color: "white" }}>Equipos instalados</Text>
          </View>
          <Table data={each}>
            <TableHeader>
              <TableCell weighting={0.2} style={{ textAlign: "center" }}>
                #
              </TableCell>
              {[
                "Linea",
                "Interno",
                "Dominio",
                "Validador",
                "Teclado",
                "MK",
              ].map((item) => (
                <TableCell style={{ textAlign: "center" }}>{item}</TableCell>
              ))}
              <TableCell weighting={0.25} style={{ textAlign: "center" }}>
                OK
              </TableCell>
            </TableHeader>
            {each && each.length > 0 ? (
              <TableBody>
                <DataTableCell
                  weighting={0.2}
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.nro}
                />
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.linea.nombre}
                />
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.interno}
                />
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.patente}
                />
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.numero_serie_validador}
                />
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.numero_serie_teclado}
                />
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={(r) => r.numero_serie_mk}
                />
                <DataTableCell
                  weighting={0.25}
                  style={{ textAlign: "center" }}
                  getContent={() => "OK"}
                />
              </TableBody>
            ) : (
              <TableBody>
                <DataTableCell
                  style={{ textAlign: "center" }}
                  getContent={() => "No hay datos"}
                />
              </TableBody>
            )}
          </Table>
          <View
            fixed
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              position: "absolute",
              top: 700,
              left: 0,
              right: 0,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{ border: 1, width: "200rem", height: "30%" }}
              ></View>
              <Text
                style={{
                  fontSize: 12,
                  border: 1,
                  paddingLeft: 2,
                }}
              >
                {"Representante Sonda"}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                paddingHorizontal: 8,
              }}
            >
              <View style={{ border: 1, width: "200rem", height: "30%" }}>
                <Image
                  src={"data:image/jpeg;base64," + empresaFinal.filename}
                />
              </View>
              <Text
                style={{
                  fontSize: 12,
                  border: 1,
                  paddingLeft: 2,
                }}
              >
                {"Representante Empresa"}
              </Text>
            </View>
          </View>
          <Text
            fixed
            style={{
              position: "absolute",
              bottom: 30,
              left: 0,
              right: 0,
              fontSize: 12,
              textAlign: "center",
              color: "grey",
            }}
            render={({ pageNumber, totalPages }) =>
              `Página ${pageNumber} de ${totalPages}`
            }
          />
        </Page>
      ))}
      <Page style={{ padding: 10 }}>
        <View
          fixed
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: "10", paddingVertical: 2 }}>
              Licitacion Publica: {getTodayFormated().slice(3)}
            </Text>
            <Text style={{ fontSize: "10", paddingVertical: 2 }}>
              Acta N°. Parcial
            </Text>
            <Text style={{ fontSize: "10", paddingVertical: 2 }}>
              Fecha: {getTodayFormated()}
            </Text>
            <Text
              style={{
                fontSize: "12",
                fontWeight: "heavy",
                paddingVertical: 2,
                paddingBottom: 10,
              }}
            >
              Empresa: {empresaFinal.nombre}
            </Text>
          </View>
          <View style={{ width: "40%" }}>
            <Image src={sonda_nacion} />
          </View>
        </View>
        <View>
          <View style={{ backgroundColor: "#005da1", textAlign: "center" }}>
            <Text style={{ color: "white" }}>Total de Equipos instalados</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              border: 1,
              borderColor: "black",
              paddingHorizontal: 2,
            }}
          >
            <Text>Total de Colectivos Autorizados</Text>
            <Text
              style={{
                borderLeftColor: "black",
                borderLeft: 2,
                paddingHorizontal: 2,
              }}
            >
              {exportDatosPdf.reduce(
                (partialSum, a) => partialSum + a.length,
                0
              )}
            </Text>
          </View>
        </View>
        <View
          fixed
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "absolute",
            top: 700,
            left: 0,
            right: 0,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View style={{ border: 1, width: "200rem", height: "30%" }}></View>
            <Text
              style={{
                fontSize: 12,
                border: 1,
                paddingLeft: 2,
              }}
            >
              {"Representante Sonda"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              paddingHorizontal: 8,
            }}
          >
            <View style={{ border: 1, width: "200rem", height: "30%" }}>
              <Image src={"data:image/jpeg;base64," + empresaFinal.filename} />
            </View>
            <Text
              style={{
                fontSize: 12,
                border: 1,
                paddingLeft: 2,
              }}
            >
              {"Representante Empresa"}
            </Text>
          </View>
        </View>
        <Text
          fixed
          style={{
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            fontSize: 12,
            textAlign: "center",
            color: "grey",
          }}
          render={({ pageNumber, totalPages }) =>
            `Página ${pageNumber} de ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
  const mandarMail = () => {
    pdf(pdfTemplate)
      .toBlob()
      .then((blob) => {
        const formData = new FormData();
        formData.append(
          "file",
          blob,
          `RegistrosCargados-${empresaFinal.nombre}-${
            new Date().toISOString().split("T")[0]
          }.pdf`
        );
        axiosClient.post("/exportar/" + empresa, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        });
      });
  };
  const exportarAExcel = () => {
    if (exportDatos.length > 0 && !loadingRegistros) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      var data = [];
      exportDatos.map((f) =>
        data.push({
          fecha: f.fecha.replace("T", " "),
          linea: f.linea.nombre,
          interno: f.interno,
          patente: f.patente,
          numero_serie_validador: f.numero_serie_validador,
          numero_serie_mk: f.numero_serie_mk,
          numero_serie_teclado: f.numero_serie_teclado,
          responsable_empresa_nombre: f.responsable_empresa_nombre,
          responsable_empresa_telefono: f.responsable_empresa_telefono,
          luz_alta: f.luz_alta === 0 ? "NO" : "SI",
          luz_posicionamiento: f.luz_posicionamiento === 0 ? "NO" : "SI",
          luz_giro: f.luz_giro === 0 ? "NO" : "SI",
          luz_tablero: f.luz_tablero === 0 ? "NO" : "SI",
          balizas: f.balizas === 0 ? "NO" : "SI",
          limpia_parabrisas: f.limpia_parabrisas === 0 ? "NO" : "SI",
          bocina: f.bocina === 0 ? "NO" : "SI",
          encendido: f.encendido === 0 ? "NO" : "SI",
          espejos: f.espejos === 0 ? "NO" : "SI",
          parabrisas: f.parabrisas === 0 ? "NO" : "SI",
          fusilera: f.fusilera === 0 ? "NO" : "SI",
          fotos_ig_originales: f.fotos_ig_originales === 0 ? "NO" : "SI",
          mk_orientacion_orificio:
            f.mk_orientacion_orificio === 0 ? "NO" : "SI",
          mk_rebarbado_orificio: f.mk_rebarbado_orificio === 0 ? "NO" : "SI",
          mk_antena_gps: f.mk_antena_gps === 0 ? "NO" : "SI",
          mk_criptado_rj45: f.mk_criptado_rj45 === 0 ? "NO" : "SI",
          consola_proteccion_cables:
            f.consola_proteccion_cables === 0 ? "NO" : "SI",
          consola_soporte: f.consola_soporte === 0 ? "NO" : "SI",
          consola_cableado_mk: f.consola_cableado_mk === 0 ? "NO" : "SI",
          consola_criptado_rj45: f.consola_criptado_rj45 === 0 ? "NO" : "SI",
          general_colocacion_gps: f.general_colocacion_gps === 0 ? "NO" : "SI",
          general_tension_power: f.general_tension_power === 0 ? "NO" : "SI",
          general_fotos_instalacion:
            f.general_fotos_instalacion === 0 ? "NO" : "SI",
          general_configuracion: f.general_configuracion === 0 ? "NO" : "SI",
          observaciones: f.observaciones === 0 ? "NO" : "SI",
          nombre_tecnico_1: f.tecnicos[0].nombre ?? "",
          contratista_tecnico_1: f.tecnicos[0]?.contratista ?? "",
          nombre_tecnico_2: f.tecnicos[1]?.nombre ?? "",
          contratista_tecnico_2: f.tecnicos[1]?.contratista ?? "",
        })
      );

      let objectMaxLength = [];
      for (let i = 0; i < data.length; i++) {
        let value =
          Object.keys(data[i]) > Object.values(data[i])
            ? Object.keys(data[i])
            : Object.values(data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 10;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? objectMaxLength[j]
                : value[j].length;
          }
        }
      }

      var wscols = [
        { width: objectMaxLength[0] }, // first column
        { width: objectMaxLength[1] }, // second column
        { width: objectMaxLength[2] }, //...
        { width: objectMaxLength[3] },
        { width: objectMaxLength[4] },
        { width: objectMaxLength[5] },
        { width: objectMaxLength[6] },
        { width: objectMaxLength[7] },
        { width: objectMaxLength[8] },
        { width: objectMaxLength[9] },
      ];

      const worksheet = XLSX.utils.json_to_sheet(data);
      worksheet["!cols"] = wscols;
      const workbook = {
        Sheets: { Registros: worksheet },
        SheetNames: ["Registros"],
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const preFile = new Blob([excelBuffer], { type: EXCEL_TYPE });
      saveAs(
        preFile,
        `RegistrosCargados-${empresaFinal.nombre}-${
          new Date().toISOString().split("T")[0]
        }` + EXCEL_EXTENSION
      );
      const formData = new FormData();
      formData.append(
        "file",
        preFile,
        `RegistrosCargados-${empresaFinal.nombre}-${
          new Date().toISOString().split("T")[0]
        }.xlsx`
      );
      axiosClient.post("exportar/" + empresa, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      window.scrollTo(0, 0);
      setDatosAlert({
        bgColor: "red-500",
        message: "Deben existir registros para exportar",
        show: true,
      });
    }
  };

  const getToday = (rest) => {
    var today = new Date();
    today.setDate(today.getDate() - rest);
    var hour = String(today.getHours()).padStart(2, "0");
    var min = String(today.getMinutes()).padStart(2, "0");
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd + "T" + hour + ":" + min;
    return today;
  };

  const [fechaInicio, setFechaInicio] = useState(getToday(7));
  const [fechaFin, setFechaFin] = useState(getToday(0));

  useEffect(() => {
    setLoadingRegistros(true);
    setLoadingPdf(true);
    axiosClient
      .get(
        "registros?empresa_id=" +
          empresa +
          "&linea_id=" +
          linea +
          "&usuario_id=" +
          operador +
          "&patente=" +
          patente +
          "&fecha_inicio=" +
          fechaInicio +
          "&fecha_fin=" +
          fechaFin +
          "&size=" +
          itemsPerPage +
          "&page=" +
          pageCount,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.registros.items);
          setLoadingRegistros(false);
          setTotalItems(response.data.registros.total);
          setTotalPages(
            Math.ceil(response.data.registros.total / itemsPerPage)
          );

          setTimeout(() => {
            setLoadingPdf(false);
          }, 5000);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
    axiosClient
      .get(
        "registros?empresa_id=" +
          empresa +
          "&linea_id=" +
          linea +
          "&usuario_id=" +
          operador +
          "&fecha_inicio=" +
          fechaInicio +
          "&fecha_fin=" +
          fechaFin +
          "&patente=" +
          patente +
          "&size=" +
          100 +
          "&page=" +
          1,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          let res = response.data.registros.items.map((f, index) => ({
            ...f,
            nro: index + 1,
          }));
          let a = [];
          while (res.length) {
            a.push(res.splice(0, 20));
          }
          setExportDatos(response.data.registros.items);
          setExportDatosPdf(a);

          setLoadingRegistros(false);
          setTimeout(() => {
            setLoadingPdf(false);
          }, 5000);
        } else {
          console.log("error");
        }
      });
    if (empresa != 0) {
      axiosClient
        .get("empresas/" + empresa, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setEmpresaFinal(response.data.empresa);
            console.log(response.data.empresa);
          } else {
            console.log("error");
          }
        });
    }
  }, [
    patente,
    empresa,
    linea,
    operador,
    fechaInicio,
    fechaFin,
    itemsPerPage,
    pageCount,
  ]);

  useEffect(() => {
    if (empresa != "0") {
      setLoadingRegistros(true);
      axiosClient
        .get("lineas?empresa_id=" + empresa, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLineas(response.data.lineas);
            setLoadingRegistros(false);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.code === 401) {
            window.location.href = "/auth/login";
          }
        });
    }
  }, [empresa]);

  function bajaHandler(id_registro) {
    setLoadingBaja(true);
    setLoadingRegistros(true);
    axiosClient
      .delete("registros/" + id_registro, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let categorias_sin_deleted = datos.filter(
            (p) => p.id !== id_registro
          );
          setDatos(categorias_sin_deleted);
          setDatosAlert({
            bgColor: "green-500",
            message: "Registro dada de baja correctamente",
            show: true,
          });
          setLoadingRegistros(false);
          setModalBajaCate(false);
          setLoadingBaja(false);
        } else {
          setDatosAlert({
            bgColor: "red-500",
            message: "Hubo un error dando de baja el Registro",
            show: true,
          });
          setLoadingRegistros(false);
          setModalBajaCate(false);
          setLoadingBaja(false);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setDatosAlert({
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error dando de baja el Registro",
          show: true,
        });
        setLoadingRegistros(false);
        setModalBajaCate(false);
        setLoadingBaja(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (datosModal) {
      setModalBajaCate(true);
    }
  }, [datosModal]);

  useEffect(() => {
    axiosClient
      .get("empresas", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setEmpresas(response.data.empresas);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  useEffect(() => {
    axiosClient
      .get("operadores/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOperadores(response.data.usuarios);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const bajaRegistro = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  const updateSelect = (value) => {
    setEmpresa(value);
    setLinea("0");
  };

  return (
    <>
      {datosAlert.show ? (
        <Alert
          bgColor={datosAlert.bgColor}
          message={datosAlert.message}
          funcionCierre={() => setDatosAlert({ ...datosAlert, show: false })}
        />
      ) : null}
      <div className="rounded shadow-lg text-left mt-3 border-2 bg-white border-gray-900 pt-6">
        <div className="flex flex-col lg:flex-row p-4 pt-0 pb-0 items-center">
          <div className="flex flex-col lg:flex-row md:w-full  lg:gap-2 lg:justify-end">
            {localStorage.getItem("role") == "True" ? (
              <div className="flex flex-col w-full lg:w-44 mb-2 lg:mb-0">
                <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                  OPERADOR
                </label>
                <select
                  name="operador"
                  value={operador}
                  onChange={(e) => {
                    setPageCount(1);
                    setOperador(e.target.value);
                  }}
                  className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                >
                  <option value="0">Todos</option>
                  {operadores.map((e) => (
                    <option key={e.id} value={e.id}>
                      {e.username}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <div className="flex flex-col w-full lg:w-40 mb-0 lg:mb-0">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                EMPRESA
              </label>
              <select
                name="empresa"
                value={empresa}
                onChange={(e) => {
                  setPageCount(1);
                  updateSelect(e.target.value);
                }}
                className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="0">Todas</option>
                {empresas.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col w-full lg:w-40 mb-0 lg:mb-0">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                LINEA
              </label>
              <select
                name="linea"
                value={linea}
                onChange={(e) => {
                  setPageCount(1);
                  setLinea(e.target.value);
                }}
                className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="0">
                  {empresa == "0" ? "Elija empresa" : "Todas"}
                </option>
                {lineas.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.nombre}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col w-full lg:w-32 mb-0 lg:mb-0">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                PATENTE
              </label>
              <input
                name="patente"
                type="text"
                value={patente}
                className="m-1 rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder="Patente"
                onChange={(e) => {
                  setPatente(e.target.value);
                  setPageCount(1);
                }}
              />
            </div>
            <div className="flex flex-col w-full lg:w-48 mb-2 lg:mb-0">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                FECHA INICIO
              </label>
              <input
                name="fecha_inicio"
                type="datetime-local"
                value={fechaInicio}
                className=" m-1 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Fecha inicio de busqueda"}
                onChange={(e) => {
                  setPageCount(1);
                  setFechaInicio(
                    e.target.value === "" ? getToday(7) : e.target.value
                  );
                }}
              />
            </div>
            <div className="flex flex-col w-full lg:w-48 mb-2 lg:mb-0">
              <label className="text-gray-500 text-xs font-medium mb-1 pl-1">
                FECHA FIN
              </label>
              <input
                name="fecha_fin"
                type="datetime-local"
                value={fechaFin}
                className=" m-1 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                placeholder={false ? "Cargando..." : "Fecha fin de busqueda"}
                onChange={(e) => {
                  setPageCount(1);
                  setFechaFin(
                    e.target.value === "" ? getToday(0) : e.target.value
                  );
                }}
              />
            </div>
          </div>
          {localStorage.getItem("role") == "True" &&
          !loadingPdf &&
          empresa != "0" &&
          datos.length !== 0 ? (
            <div className="w-full lg:w-1/12">
              <PDFDownloadLink
                document={pdfTemplate}
                className={
                  "bg-green-600 flex flex-row items-center font-medium text-white text-xl py-1 mt-4 mx-1 lg:ml-1 -ml-0.5 rounded-md focus:outline-none transition duration-300 hover:bg-green-800  justify-center"
                }
                fileName={`RegistrosCargados-${empresaFinal.nombre}-${
                  new Date().toISOString().split("T")[0]
                }.pdf`}
              >
                <i className={"fas fa-file-export text-xl px-1"}></i>
                PDF
              </PDFDownloadLink>
            </div>
          ) : (
            <button
              className={
                "bg-gray-600 flex cursor-not-allowed flex-row items-center font-medium text-white text-xl py-1 mx-1 mt-4 rounded-md focus:outline-none transition duration-300  w-full lg:w-1/12 justify-center"
              }
              title="Debe seleccionar una empresa y deben existir registros"
            >
              <i className={"fas fa-file-export text-xl px-1"}></i> PDF
            </button>
          )}
          {empresa != "0" && datos.length !== 0 ? (
            <button
              onClick={exportarAExcel}
              className="bg-green-600 flex flex-row items-center font-medium text-white text-xl py-1 mx-1 mt-4 rounded-md focus:outline-none transition duration-300 hover:bg-green-800 w-full lg:w-1/12 justify-center"
            >
              <svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5l2.9 4.5M13 9V3.5L18.5 9H13Z"
                ></path>
              </svg>
              Excel
            </button>
          ) : (
            <button
              className={
                "bg-gray-600 flex cursor-not-allowed flex-row items-center font-medium text-white text-xl py-1 mx-1 mt-4 rounded-md focus:outline-none transition duration-300  w-full lg:w-1/12 justify-center"
              }
              title="Debe seleccionar una empresa y deben existir registros"
            >
              <svg width="1.2em" height="1.2em" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5l2.9 4.5M13 9V3.5L18.5 9H13Z"
                ></path>
              </svg>
              Excel
            </button>
          )}
        </div>

        <div className="lg:mt-2 p-4 lg:py-6">
          {!loadingRegistros && datos && datos.length > 0 ? (
            <>
              {datos.map((p) => (
                <div
                  className="border rounded p-2 m-1 lg:m-0 flex flex-col justify-between lg:grid lg:grid-cols-10 lg:gap-2"
                  key={p.id}
                >
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">FECHA</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.fecha.replace("T", " ")}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">EMPRESA</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      <span className="bg-orange-500 text-white rounded px-2 text-sm font-medium mt-1">
                        {p.linea.empresa.nombre}
                      </span>
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">LINEA</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      <span className="bg-blue-500 text-white rounded px-2 text-sm font-medium mt-1">
                        {p.linea.nombre}
                      </span>
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">PATENTE</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.patente}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">INTERNO</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.interno}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      N° VALIDADOR
                    </p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.numero_serie_validador}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">N° MK</p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.numero_serie_mk}
                    </p>
                  </div>
                  <div className="lg:border-r lg:m-0 mb-2">
                    <p className="text-sm font-bold text-gray-800">
                      N° TECLADO
                    </p>
                    <p className="text-sm text-gray-600 mt-1 font-medium">
                      {p.numero_serie_teclado}
                    </p>
                  </div>
                  <div className="lg:m-0 mb-2 col-span-2">
                    <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-2 mt-2 lg:mt-1">
                      <Link
                        className="bg-indigo-500 text-white px-1 rounded py-0.5 lg:py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 text-sm"
                        to={`/admin/edit-registro/${p.id}`}
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                          <path
                            d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        VER / EDITAR
                      </Link>
                      <button
                        onClick={() => setDatosModal(p)}
                        className="bg-red-500 text-white px-4 rounded py-0.5 lg:py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 text-sm"
                      >
                        <svg className="w-5 h-5" viewBox="0 0 24 24">
                          <path
                            d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        BAJA
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div class="flex-1 flex justify-between sm:hidden">
                  <div>
                    {pageCount > 1 ? (
                      <>
                        <button
                          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          onClick={() => setPageCount(pageCount - 1)}
                        >
                          {" "}
                          Anterior{" "}
                        </button>
                      </>
                    ) : null}
                  </div>
                  <div>
                    {pageCount < totalPages ? (
                      <>
                        <button
                          class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          onClick={() => setPageCount(pageCount + 1)}
                        >
                          {" "}
                          Siguiente{" "}
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
                <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p class="text-sm text-gray-700">
                      Mostrando{" "}
                      <span class="font-medium">
                        {" "}
                        {(pageCount - 1) * itemsPerPage + 1}{" "}
                      </span>{" "}
                      a
                      <span class="font-medium">
                        {" "}
                        {pageCount * itemsPerPage > totalItems
                          ? totalItems
                          : pageCount * itemsPerPage}{" "}
                      </span>
                      de un total de
                      <span class="font-medium"> {totalItems}</span>
                    </p>
                  </div>
                  <div>
                    <nav
                      class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                      aria-label="Pagination"
                    >
                      {pageCount > 1 ? (
                        <>
                          <button
                            onClick={() => setPageCount(pageCount - 1)}
                            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <svg
                              class="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </>
                      ) : null}
                      {[...Array(totalPages)].map((x, i) => (
                        <button
                          aria-current="page"
                          onClick={() => setPageCount(i + 1)}
                          class={
                            i + 1 === pageCount
                              ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                              : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                          }
                        >
                          {" "}
                          {i + 1}{" "}
                        </button>
                      ))}

                      {pageCount < totalPages ? (
                        <>
                          <button
                            onClick={() => setPageCount(pageCount + 1)}
                            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          >
                            <svg
                              class="h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                        </>
                      ) : null}
                    </nav>
                  </div>
                </div>
              </div>
            </>
          ) : !loadingRegistros && !datos.length > 0 ? (
            <div className="rounded-lg bg-purple-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <p className="text-xl font-bold flex flex-row justify-center items-center gap-2">
                <svg className="w-8 h-8 text-purple-800" viewBox="0 0 24 24">
                  <path
                    d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                    fill="currentColor"
                  ></path>
                </svg>
                No se encontraron resultados
              </p>
            </div>
          ) : loadingRegistros ? (
            <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
                <path
                  d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                  opacity=".5"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                  fill="currentColor"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 12 12"
                    to="360 12 12"
                    dur="1s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </path>
              </svg>
            </div>
          ) : null}
        </div>
        {modalBajaCate && datosModal ? (
          <ModalBaja
            titulo={"Baja de Registro"}
            descripcion={`¿Desea dar de baja el registro ?`}
            funcionCierre={cerrarModal}
            funcionAccion={bajaRegistro}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableRegistro;
