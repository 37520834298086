import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../config/axios";
import { Link } from "react-router-dom";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";

function TableEmpresas() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;
  const { modalBajaCate, setModalBajaCate } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datosModal, setDatosModal] = useState(null);
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [alertData, setAlertData] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });

  function bajaHandler(id_empresa) {
    setLoadingBaja(true);
    axiosClient
      .delete("empresas/" + id_empresa, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let empresas_sin_deleted = datos.filter((p) => p.id !== id_empresa);
          setAlertData({
            bgColor: "green-500",
            message: "Se dió de baja la empresa correctamente",
            show: true,
          });
          setModalBajaCate(false);
          setDatos(empresas_sin_deleted);
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
        } else {
          setModalBajaCate(false);
          console.log("error");
          setLoadingBaja(false);
          setUpdateFlag(!updateFlag);
          setAlertData({
            bgColor: "red-500",
            message:
              "Hubo un error dando de baja la empresa, intentá más tarde",
            show: true,
          });
        }
        //setActualizando(false);
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlertData({
          bgColor: "red-500",
          message: "Hubo un error dando de baja la empresa, intentá más tarde",
          show: true,
        });
        setModalBajaCate(false);
        console.log(err);
        setLoadingBaja(false);
      });
  }

  //setActualizando(true);
  useEffect(() => {
    setLoading(true);
    axiosClient
      .get("empresas?inactivos=True", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setDatos(response.data.empresas);
          setLoading(false);
          //setHabilitarEdicion(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, [updateFlag]);

  useEffect(() => {
    if (datosModal) {
      setModalBajaCate(true);
    }
  }, [datosModal]);

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const darDeBajaCate = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  useEffect(() => {}, [modalBajaCate]);

  return (
    <>
      {alertData.show ? (
        <Alert
          bgColor={alertData.bgColor}
          message={alertData.message}
          funcionCierre={() => {
            setAlertData({ ...alertData, show: false });
          }}
        />
      ) : null}

      <div className="rounded-lg shadow-lg text-left mt-4 border-2 bg-white border-gray-900 pt-6 ">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 justify-between p-4 pt-0 pb-0 items-center">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">
              {" "}
              Listado de Empresas
            </h2>
            <p className=" pt-2 text-gray-500">
              Acá podés crear y administrar empresas. Una empresa tiene una o
              mas lineas asociadas.
            </p>
          </div>
          <Link
            className="mb-4 bg-green-500 hover:bg-green-600 transition duration-300 px-6 py-2 text-white font-medium text-lg rounded flex flex-row justify-center items-center gap-2 w-full lg:w-2/12"
            to="/admin/add-empresa"
          >
            <svg className="w-6 h-6" viewBox="0 0 24 24">
              <path
                d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m-5-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                fill="currentColor"
              ></path>
            </svg>
            Nueva Empresa
          </Link>
        </div>
        <div className=" p-4 py-12">
          {!loading && datos.length > 0 ? (
            datos.map((p) => (
              <div
                className="border rounded p-2 flex flex-col justify-between lg:grid lg:grid-cols-3 lg:gap-4"
                key={p.id}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">
                    NOMBRE DE EMPRESA
                  </p>
                  <p className="text-sm text-gray-600 mt-1 font-medium">
                    {p.nombre}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ESTADO</p>
                  <span
                    className={`bg-${
                      p.activo === "True" ? "green-500" : "red-500"
                    } text-white rounded px-2 text-sm font-medium mt-1`}
                  >
                    {p.activo === "True" ? "Activo" : "Oculto"}
                  </span>
                </div>
                <div className="lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                  <div className="lg:grid lg:grid-cols-2 flex flex-col gap-2 text-sm lg:text-md mt-1">
                    <Link
                      className="bg-indigo-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 w-full text-sm"
                      to={`/admin/edit-empresa/${p.id}`}
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      VER / EDITAR
                    </Link>
                    <button
                      onClick={() => setDatosModal(p)}
                      className="bg-red-500 text-white px-4 rounded py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 w-full text-sm"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      BAJA
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : loading ? (
            <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
                <path
                  d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                  opacity=".5"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                  fill="currentColor"
                >
                  <animateTransform
                    attributename="transform"
                    type="rotate"
                    from="0 12 12"
                    to="360 12 12"
                    dur="1s"
                    repeatcount="indefinite"
                  ></animateTransform>
                </path>
              </svg>
            </div>
          ) : !datos.length > 0 ? (
            <div className="rounded-lg bg-purple-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <p className="text-xl font-bold flex flex-row justify-center items-center gap-2">
                <svg className="w-8 h-8 text-purple-800" viewBox="0 0 24 24">
                  <path
                    d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                    fill="currentColor"
                  ></path>
                </svg>
                Aún no hay empresas dadas de alta
              </p>
            </div>
          ) : null}
        </div>
        {datosModal && modalBajaCate ? (
          <ModalBaja
            titulo={"Baja de Empresa"}
            descripcion={`¿ Desea dar de baja la Empresa ||'${datosModal.nombre}'|| ?`}
            funcionCierre={cerrarModal}
            funcionAccion={darDeBajaCate}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableEmpresas;
