import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../../config/axios";
import AdminContext from "context/admin/adminContext";
import ModalBaja from "components/Modals/ModalBaja";
import Alert from "components/Alerts/Alert";

function TableLinea() {
  const adminContext = useContext(AdminContext);
  const { modalBajaCate, setModalBajaCate, checkJWT } = adminContext;
  const [datos, setDatos] = useState([]);
  const [loadingLineas, setLoadingLineas] = useState(false);
  const [datosModal, setDatosModal] = useState(null);
  const [empresa, setEmpresa] = useState("0");
  const [empresas, setEmpresas] = useState([]);
  const [loadingBaja, setLoadingBaja] = useState(false);
  const [datosAlert, setDatosAlert] = useState({
    bgColor: "green-500",
    message: "",
    show: false,
  });

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  useEffect(() => {
    if (empresa != "0") {
      setLoadingLineas(true);
      axiosClient
        .get("lineas?inactivos=True&empresa_id=" + empresa, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setDatos(response.data.lineas);
            //setHabilitarEdicion(false);
            setLoadingLineas(false);
          } else {
            console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.code === 401) {
            window.location.href = "/auth/login";
          }
        });
    }
  }, [empresa]);

  function bajaHandler(id_linea) {
    setLoadingBaja(true);
    setLoadingLineas(true);
    axiosClient
      .delete("lineas/" + id_linea, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let categorias_sin_deleted = datos.filter((p) => p.id !== id_linea);
          setDatos(categorias_sin_deleted);
          setDatosAlert({
            bgColor: "green-500",
            message: "Linea dada de baja correctamente",
            show: true,
          });
          setLoadingLineas(false);
          setModalBajaCate(false);
          setLoadingBaja(false);
        } else {
          setDatosAlert({
            bgColor: "red-500",
            message: "Hubo un error dando de baja la Linea",
            show: true,
          });
          setLoadingLineas(false);
          setModalBajaCate(false);
          setLoadingBaja(false);
        }
      })
      .catch((err) => {
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setDatosAlert({
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error dando de baja el Linea",
          show: true,
        });
        setLoadingLineas(false);
        setModalBajaCate(false);
        setLoadingBaja(false);
        console.log(err);
      });
  }

  useEffect(() => {
    if (datosModal) {
      setModalBajaCate(true);
    }
  }, [datosModal, setModalBajaCate]);

  useEffect(() => {
    axiosClient
      .get("empresas", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setEmpresas(response.data.empresas);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  const cerrarModal = () => {
    setDatosModal(null);
    setModalBajaCate(false);
  };

  const bajaLinea = () => {
    if (datosModal) {
      bajaHandler(datosModal.id);
    }
  };

  const updateSelect = (value) => {
    if (value !== 0) {
      setEmpresa(value);
    }
  };

  return (
    <>
      {datosAlert.show ? (
        <Alert
          bgColor={datosAlert.bgColor}
          message={datosAlert.message}
          funcionCierre={() => setDatosAlert({ ...datosAlert, show: false })}
        />
      ) : null}
      <div className="rounded shadow-lg text-left mt-4 border-2 bg-white border-gray-900 pt-6">
        <div className="flex flex-col lg:flex-row justify-between p-4 pt-0 pb-0 items-center">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">
              {" "}
              Listado de Lineas
            </h2>
            <p className=" pt-2 text-gray-500">
              Acá podés revisar el detalle de todas las lineas por empresa
            </p>
          </div>
          <div className="flex flex-col lg:flex-row lg:w-1/2 w-full lg:gap-2 lg:justify-end m-2">
            <div className="flex flex-col w-72 lg:w-96">
              <select
                name="empresa"
                value={empresa}
                onChange={(e) => updateSelect(e.target.value)}
                className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              >
                <option value="0">Seleccione una empresa</option>
                {empresas.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.nombre}
                  </option>
                ))}
              </select>
            </div>

            <Link
              className="mt-1 mb-4 bg-green-500 hover:bg-green-600 transition duration-300 px-6 py-2 text-white font-medium text-lg rounded flex flex-row justify-center items-center gap-2 w-full lg:w-4/12"
              to="/admin/add-linea"
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24">
                <path
                  d="M17 13h-4v4h-2v-4H7v-2h4V7h2v4h4m-5-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                  fill="currentColor"
                ></path>
              </svg>
              Nueva Linea
            </Link>
          </div>
        </div>
        <div className="lg:mt-2 p-4 lg:py-6">
          {!loadingLineas && datos && datos.length > 0 ? (
            datos.map((p) => (
              <div
                className="border rounded p-2 flex flex-col justify-between lg:grid lg:grid-cols-4 lg:gap-4"
                key={p.id}
              >
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">NOMBRE</p>
                  <p className="text-sm text-gray-600 mt-1 font-medium">
                    {p.nombre}
                  </p>
                </div>
                <div className="lg:border-r lg:m-0 mb-2">
                  <p className="text-sm font-bold text-gray-800">ESTADO</p>
                  <span
                    className={`bg-${
                      p.activo === "True" ? "green-500" : "red-500"
                    } text-white rounded px-2 text-sm font-medium`}
                  >
                    {p.activo === "True" ? "Activo" : "Oculto"}
                  </span>
                </div>
                <div className="lg:m-0 mb-2 col-span-2">
                  <p className="text-sm font-bold text-gray-800">ACCIONES</p>
                  <div className="flex flex-col lg:grid lg:grid-cols-2 gap-2 mt-2 lg:mt-1">
                    <Link
                      className="bg-indigo-500 text-white px-4 rounded py-0.5 lg:py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-indigo-600 transition duration-300 text-sm"
                      to={`/admin/edit-linea/${p.id}`}
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10h-2a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8V2m6.78 1a.69.69 0 0 0-.48.2l-1.22 1.21l2.5 2.5L20.8 5.7c.26-.26.26-.7 0-.95L19.25 3.2c-.13-.13-.3-.2-.47-.2m-2.41 2.12L9 12.5V15h2.5l7.37-7.38l-2.5-2.5z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      VER / EDITAR
                    </Link>
                    <button
                      onClick={() => setDatosModal(p)}
                      className="bg-red-500 text-white px-4 rounded py-0.5 lg:py-0.5 font-semibold flex flex-row items-center justify-center gap-2 hover:bg-red-600 transition duration-300 text-sm"
                    >
                      <svg className="w-5 h-5" viewBox="0 0 24 24">
                        <path
                          d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      BAJA
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (!loadingLineas && !datos.length > 0) || empresa == 0 ? (
            <div className="rounded-lg bg-purple-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <p className="text-xl font-bold flex flex-row justify-center items-center gap-2">
                <svg className="w-8 h-8 text-purple-800" viewBox="0 0 24 24">
                  <path
                    d="M13 9h-2V7h2m0 10h-2v-6h2m-1-9A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                    fill="currentColor"
                  ></path>
                </svg>
                {empresa != "0" && empresa != 0
                  ? "Aún no hay lineas dadas de alta para esa empresa"
                  : "Debe seleccionar una empresa"}
              </p>
            </div>
          ) : loadingLineas ? (
            <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 w-full flex justify-center items-center">
              <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
                <path
                  d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                  opacity=".5"
                  fill="currentColor"
                ></path>
                <path
                  d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                  fill="currentColor"
                >
                  <animateTransform
                    attributename="transform"
                    type="rotate"
                    from="0 12 12"
                    to="360 12 12"
                    dur="1s"
                    repeatcount="indefinite"
                  ></animateTransform>
                </path>
              </svg>
            </div>
          ) : null}
        </div>
        {modalBajaCate && datosModal ? (
          <ModalBaja
            titulo={"Baja de Linea"}
            descripcion={`¿Desea dar de baja la linea ||'${datosModal.nombre}'|| ?`}
            funcionCierre={cerrarModal}
            funcionAccion={bajaLinea}
            loadingState={loadingBaja}
          />
        ) : null}
      </div>
    </>
  );
}

export default TableLinea;
