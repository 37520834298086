import ReactDotWrapper from "../../components/Dots/ReactDotWrapper";
import { useEffect, useState } from "react";
import axiosClient from "config/axios";
import Alert from "components/Alerts/Alert";
export default function AltaInstalacion() {
  const [empresas, setEmpresas] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [instaladores, setInstaladores] = useState([]);

  const [instalador_1, setInstalador1] = useState("");
  const [instalador_2, setInstalador2] = useState("");
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [puntos, setPuntos] = useState([]);
  const [actualizando, setActualizando] = useState(false);

  const [selectedFile, setSelectedFile] = useState([]);
  const [preview, setPreview] = useState([]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = selectedFile.map((each) => URL.createObjectURL(each));
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const newData = [...selectedFile];
    newData[selectedFile.length] = e.target.files[0];
    setSelectedFile(newData);
  };

  useEffect(() => {}, [preview]);

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });

  const [data, setData] = useState({
    interno: "",
    patente: "",
    numero_serie_validador: "",
    numero_serie_mk: "",
    numero_serie_teclado: "",
    luz_alta: false,
    luz_posicionamiento: false,
    luz_giro: false,
    luz_tablero: false,
    balizas: false,
    limpia_parabrisas: false,
    bocina: false,
    encendido: false,
    espejos: false,
    parabrisas: false,
    fusilera: false,
    fotos_ig_originales: false,
    mk_orientacion_orificio: false,
    mk_rebarbado_orificio: false,
    mk_antena_gps: false,
    mk_criptado_rj45: false,
    consola_proteccion_cables: false,
    consola_soporte: false,
    consola_cableado_mk: false,
    consola_criptado_rj45: false,
    general_colocacion_gps: false,
    general_tension_power: false,
    general_fotos_instalacion: false,
    general_configuracion: false,
    observaciones: "",
    puntos_imagen: "",
    responsable_empresa_nombre: "",
    responsable_empresa_telefono: "",
    id_tecnicos: "",
  });

  const updateValueInput = (e) => {
    var newDatos = { ...data };
    newDatos[e.target.name] = e.target.value;
    setData(newDatos);
  };
  const updateValueCheckbox = (e) => {
    var newDatos = { ...data };
    newDatos[e.target.name] = e.target.checked;
    setData(newDatos);
  };

  const sendFile = (id_registro) => {
    var formData = new FormData();
    selectedFile.forEach((file) => {
      formData.append("files", file);
    });
    // formData.append("files", selectedFile);
    axiosClient
      .post("registros/files/" + id_registro, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  };

  const actualizarDatos = () => {
    if (selectedEmpresa === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar una empresa",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (selectedLinea === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar una linea",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.interno === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar un interno",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.patente === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar una patente",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (instalador_1 === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar al menos un tecnico instalador",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.numero_serie_validador === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar un numero de serie de validador",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.numero_serie_mk === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar un numero de serie de mounting kit",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.numero_serie_teclado === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar un numero de serie de teclado",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (
      JSON.stringify(puntos) === "[]" ||
      JSON.stringify(puntos) === undefined
    ) {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe marcar el dispositivo en la imagen",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.responsable_empresa_nombre === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar el nombre del responsable de la empresa",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.responsable_empresa_telefono === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar el telefono del responsable de la empresa",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }

    setActualizando(true);
    var payload = {
      ...data,
      linea_id: selectedLinea,
      id_tecnicos:
        instalador_1 + (instalador_2 !== "" ? "," + instalador_2 : ""),
      numero_serie_validador: data.numero_serie_validador,
      numero_serie_mk: data.numero_serie_mk,
      numero_serie_teclado: data.numero_serie_teclado,
      puntos_imagen: JSON.stringify(puntos),
      luz_alta: data.luz_alta ? 1 : 0,
      luz_posicionamiento: data.luz_posicionamiento ? 1 : 0,
      luz_giro: data.luz_giro ? 1 : 0,
      luz_tablero: data.luz_tablero ? 1 : 0,
      balizas: data.balizas ? 1 : 0,
      limpia_parabrisas: data.limpia_parabrisas ? 1 : 0,
      bocina: data.bocina ? 1 : 0,
      encendido: data.encendido ? 1 : 0,
      espejos: data.espejos ? 1 : 0,
      parabrisas: data.parabrisas ? 1 : 0,
      fusilera: data.fusilera ? 1 : 0,
      fotos_ig_originales: data.fotos_ig_originales ? 1 : 0,
      mk_orientacion_orificio: data.mk_orientacion_orificio ? 1 : 0,
      mk_rebarbado_orificio: data.mk_rebarbado_orificio ? 1 : 0,
      mk_antena_gps: data.mk_antena_gps ? 1 : 0,
      mk_criptado_rj45: data.mk_criptado_rj45 ? 1 : 0,
      consola_proteccion_cables: data.consola_proteccion_cables ? 1 : 0,
      consola_soporte: data.consola_soporte ? 1 : 0,
      consola_cableado_mk: data.consola_cableado_mk ? 1 : 0,
      consola_criptado_rj45: data.consola_criptado_rj45 ? 1 : 0,
      general_colocacion_gps: data.general_colocacion_gps ? 1 : 0,
      general_tension_power: data.general_tension_power ? 1 : 0,
      general_fotos_instalacion: data.general_fotos_instalacion ? 1 : 0,
      general_configuracion: data.general_configuracion ? 1 : 0,
      empresa_id: selectedEmpresa,
    };
    axiosClient
      .post("/registros/crear", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // Carga los archivos
        if (selectedFile.length > 0) {
          sendFile(response.data.registro_id);
        }
        setActualizando(false);
        setAlert({
          ...alert,
          bgColor: "green-500",
          message: "Registro creado exitosamente",
          show: true,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlert({
          ...alert,
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error creando el registro.",
          show: true,
        });
        setActualizando(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  useEffect(() => {
    axiosClient("empresas", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setEmpresas(res.data.empresas);
    });
    axiosClient("lineas?empresa_id=" + selectedEmpresa, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setLineas(res.data.lineas);
    });
    axiosClient("tecnicos", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setInstaladores(res.data.tecnicos);
    });
  }, []);

  useEffect(() => {
    axiosClient("lineas?empresa_id=" + selectedEmpresa, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setLineas(res.data.lineas);
    });
  }, [selectedEmpresa]);

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <div
        className={`rounded shadow-lg text-left ${
          alert.show ? "" : "mt-4"
        } border-2 bg-white border-gray-900 `}
      >
        <div className="flex flex-col p-4 ">
          <div className="flex flex-col gap-2 ">
            <div>
              <h2 className="text-2xl font-semibold leading-tight">
                Cargar registro de instalacion
              </h2>
            </div>
            <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0  ">
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  EMPRESA
                </label>
                <select
                  onChange={(e) => setSelectedEmpresa(e.target.value)}
                  value={selectedEmpresa}
                  className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                >
                  <option default disabled value="">
                    Seleccione una empresa
                  </option>
                  {empresas.map((empresa) => (
                    <option key={empresa.id} value={empresa.id}>
                      {empresa.nombre}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  LINEA
                </label>
                <select
                  onChange={(e) => setSelectedLinea(e.target.value)}
                  value={selectedLinea}
                  className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                >
                  <option default disabled value="">
                    Seleccione una línea
                  </option>
                  {lineas.map((linea) => (
                    <option key={linea.id} value={linea.id}>
                      {linea.nombre}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-2 ">
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  INTERNO
                </label>
                <input
                  type="text"
                  name="interno"
                  onChange={updateValueInput}
                  value={data.interno}
                  placeholder="Ingrese el numero de interno"
                  className="bg-gray-100 w-full rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  PATENTE
                </label>
                <input
                  type="text"
                  name="patente"
                  onChange={updateValueInput}
                  value={data.patente}
                  placeholder="Ingrese la patente del vehiculo"
                  className="bg-gray-100 w-full rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                />
              </div>
            </div>
            <div
              className={`lg:grid lg:grid-cols-${
                instalador_1 !== "" ? "2" : 1
              } gap-6 space-y-4 lg:space-y-0 mt-2 `}
            >
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  TECNICO INSTALADOR N° 1
                </label>
                <select
                  onChange={(e) => setInstalador1(e.target.value)}
                  value={instalador_1}
                  className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                >
                  <option default disabled value="">
                    Seleccione un tecnico instalador
                  </option>
                  {instaladores.map((instalador) => (
                    <option key={instalador.id} value={instalador.id}>
                      {instalador.nombre}
                    </option>
                  ))}
                </select>
              </div>
              {instalador_1 !== "" && (
                <div className="flex flex-col">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    TECNICO INSTALADOR N° 2
                  </label>
                  <select
                    onChange={(e) => setInstalador2(e.target.value)}
                    value={instalador_2}
                    className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  >
                    <option default disabled value="">
                      Seleccione un tecnico instalador
                    </option>
                    {instaladores.map((instalador) => (
                      <option key={instalador.id} value={instalador.id}>
                        {instalador.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="bg-gray-300 px-2 py-2 my-2">
              <div className="flex lg:flex-row flex-col justify-evenly ">
                <div className="text-center flex flex-col">
                  <label className="text-base text-black font-medium mb-1">
                    Serie Validador
                  </label>

                  <input
                    type="text"
                    name="numero_serie_validador"
                    onChange={updateValueInput}
                    value={data.numero_serie}
                    className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
                <div className="text-center flex flex-col">
                  <label className="text-black text-base font-medium mb-1">
                    Serie Mounting Kit
                  </label>
                  <input
                    type="text"
                    name="numero_serie_mk"
                    value={data.numero_serie_mk}
                    onChange={updateValueInput}
                    className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
                <div className="text-center flex flex-col">
                  <label className="text-black text-base font-medium mb-1">
                    Serie Teclado
                  </label>
                  <input
                    type="text"
                    name="numero_serie_teclado"
                    value={data.numero_serie_teclado}
                    onChange={updateValueInput}
                    className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col justify-center items-center">
              <p className="bg-white border border-black text-center w-full lg:w-1/2">
                CONTROL DE INSTALACIONES
              </p>
            </div>
            <div className="flex flex-col lg:flex-row justify-evenly">
              <div className="flex flex-col ">
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="luz_alta"
                    value={data.luz_alta}
                  />
                  Luz alta
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="luz_posicionamiento"
                    value={data.luz_posicionamiento}
                  />
                  Luz posicionamiento
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="luz_giro"
                    value={data.luz_giro}
                  />
                  Luz giro
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="luz_tablero"
                    value={data.luz_tablero}
                  />
                  Luz tablero
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="balizas"
                    value={data.balizas}
                  />
                  Balizas
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="limpia_parabrisas"
                    value={data.limpia_parabrisas}
                  />
                  Limpia parabrisas
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="bocina"
                    value={data.bocina}
                  />
                  Bocina
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="encendido"
                    value={data.encendido}
                  />
                  Encendido
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="espejos"
                    value={data.espejos}
                  />
                  Espejos
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="parabrisas"
                    value={data.parabrisas}
                  />
                  Parabrisas
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="fusilera"
                    value={data.fusilera}
                  />
                  Fusilera
                </label>
                <label className="text-gray-500 text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="fotos_ig_originales"
                    value={data.fotos_ig_originales}
                  />
                  Fotos de IG originales
                </label>
              </div>
              <div>
                <ReactDotWrapper setPuntos={setPuntos} />
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 my-2 py-2">
            <div className="justify-center items-center flex flex-col">
              <p className="bg-white border border-black text-center w-full lg:w-1/2 ">
                INSTALACION
              </p>
            </div>
            <div className="bg-gray-300 px-2 py-2">
              <p className="text-xl">MK</p>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="mk_orientacion_orificio"
                    value={data.mk_orientacion_orificio}
                  />
                  Orientacion del orificio (salida cables) del Validador
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="mk_rebarbado_orificio"
                    value={data.mk_rebarbado_orificio}
                  />
                  Rebarbado del orificio
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="mk_antena_gps"
                    value={data.mk_antena_gps}
                  />
                  Correcta fijación de la antena GPS
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="mk_criptado_rj45"
                    value={data.mk_criptado_rj45}
                  />
                  Correcto crimpado de RJ45 en MK
                </label>
              </div>
            </div>
            <div className="bg-gray-300 px-2 py-2">
              <p className="text-xl">CONSOLA</p>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="consola_proteccion_cables"
                    value={data.consola_proteccion_cables}
                  />
                  Protección de cables con corrugado y precintos
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="consola_soporte"
                    value={data.consola_soporte}
                  />
                  Estado de soporte consola
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="consola_cableado_mk"
                    value={data.consola_cableado_mk}
                  />
                  Correcto cableado de MK a consola
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="consola_criptado_rj45"
                    value={data.consola_criptado_rj45}
                  />
                  Correcto crimpado de RJ45 en consola
                </label>
              </div>
            </div>
            <div className="bg-gray-300 px-2 py-2">
              <p className="text-xl">GENERAL</p>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="general_colocacion_gps"
                    value={data.general_colocacion_gps}
                  />
                  Colocación de antena GPS en lugar y forma apropiada
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="general_tension_power"
                    value={data.general_tension_power}
                  />
                  Toma de tensión cable POWER en lugar apropiado (Fusilera)
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="general_fotos_instalacion"
                    value={data.general_fotos_instalacion}
                  />
                  Fotos instalación final
                </label>
              </div>
              <div>
                <label className="text-black text-base font-medium mb-1">
                  <input
                    type="checkbox"
                    onChange={updateValueCheckbox}
                    name="general_configuracion"
                    value={data.general_configuracion}
                  />
                  Configuración
                </label>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex flex-col my-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              OBSERVACIONES
            </label>
            <textarea
              value={data.observaciones}
              onChange={updateValueInput}
              name="observaciones"
              placeholder="Ingrese observaciones a tener en cuenta"
              className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
              rows={3}
            ></textarea>
          </div>
          <hr />
          <div className="flex flex-col my-2">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FOTOS ADJUNTAS
            </label>
            <div>
              <input
                type="file"
                onChange={onSelectFile}
                id="selectedFile"
                accept="image/*"
                className="hidden"
              />
              <input
                type="button"
                value="Subir foto"
                className=" p-1 px-3 mt-1 mb-1 hover:bg-gray-300"
                onClick={() => document.getElementById("selectedFile").click()}
              />
              <div className="flex flex-row flex-wrap gap-2">
                {selectedFile &&
                  preview.map((each, index) => (
                    <>
                      <img className="w-44 h-44 z-1 -mr-4" src={each} />
                      <svg
                        className=" z-10 cursor-pointer"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                        onClick={() =>
                          setSelectedFile(
                            selectedFile.filter((_, i) => i !== index)
                          )
                        }
                      >
                        <path
                          fill="#FF0000"
                          d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"
                        ></path>
                      </svg>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <hr />
          <div>
            <p className="text-md font-bold my-2">
              RESPONSABLE EMPRESA DE TRANSPORTE
            </p>
            <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-2 ">
              <div className="flex flex-col">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  NOMBRE
                </label>
                <input
                  type="text"
                  name="responsable_empresa_nombre"
                  onChange={updateValueInput}
                  value={data.responsable_empresa_nombre}
                  placeholder="Ingrese nombre del responsable"
                  className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                />
              </div>
              <div className="flex flex-col my-2">
                <label className="text-gray-500 text-xs font-medium mb-1">
                  TELEFONO
                </label>
                <input
                  type="text"
                  name="responsable_empresa_telefono"
                  onChange={updateValueInput}
                  value={data.responsable_empresa_telefono}
                  placeholder="Ingrese telefono del responsable"
                  className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end m-4">
            <button
              onClick={actualizarDatos}
              className={
                actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-xl px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-xl px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-2/12 justify-center lg:mt-1.5"
              }
              disabled={actualizando}
            >
              {actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )}
              {actualizando ? "Cargando registro" : "Cargar registro"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
