function LoadingCard() {
  return (
    <div>
      <div
        className={
          "relative flex flex-col min-w-0 mt-6 break-words w-full mb-6 shadow-lg rounded border-indigo-700 border-2 my-12 py-28 bg-indigo-200"
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-start">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-center">
              <div className="flex justify-center mt-4">
                <p className="font-medium text-lg text-center flex justify-center w-3/4">
                  {" "}
                  <svg
                    className="w-28 h-28 animate-spin text-indigo-500"
                    viewBox="0 0 48 48"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 24c0 11.046 8.954 20 20 20v0c11.046 0 20-8.954 20-20S35.046 4 24 4"></path>
                      <path d="M36 24c0-6.627-5.373-12-12-12s-12 5.373-12 12s5.373 12 12 12v0"></path>
                    </g>
                  </svg>
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default LoadingCard;
