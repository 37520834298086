function Alert({ bgColor, message, funcionCierre }) {
  return (
    <div
      className={`bg-${bgColor} mt-4 rounded text-white text-lg px-4 py-2 font-medium flex flex-col lg:flex-row justify-between items-center`}
    >
      <div className="flex flex-row items-center gap-2">
        {bgColor.includes("red") ? (
          <svg className="w-6 h-6" viewBox="0 0 256 256">
            <path
              d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm-8 56a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12z"
              fill="currentColor"
            ></path>
          </svg>
        ) : (
          <svg className="w-6 h-6" viewBox="0 0 256 256">
            <path
              d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
              fill="currentColor"
            ></path>
          </svg>
        )}

        {message}
      </div>
      <button
        className="font-medium text-white"
        onClick={() => {
          funcionCierre();
        }}
      >
        X
      </button>
    </div>
  );
}

export default Alert;
