import React, { useEffect, useState, useContext } from "react";

// components

import CardTable from "components/Cards/CardTable";
import AdminContext from "context/admin/adminContext";
import LoadingCard from "components/Cards/LoadingCard";

export default function Dashboard() {
  const [mainLoading, setMainLoading] = useState(true);
  const adminContext = useContext(AdminContext);
  const { checkJWT, JWT_Flag } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  useEffect(() => {
    if (JWT_Flag && JWT_Flag === window.location.pathname) {
      setMainLoading(false);
    }
  }, [JWT_Flag]);

  return <>{mainLoading ? <LoadingCard /> : <CardTable />}</>;
}
