import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import FormUsuario from "views/admin/FormUsuario.js";
import FormEmpresa from "views/admin/FormEmpresa.js";
import FormLinea from "views/admin/FormLinea.js";
import EditarLinea from "views/admin/EditarLinea.js";
import EditarEmpresa from "views/admin/EditarEmpresa.js";
import EditarUsuario from "views/admin/EditarUsuario.js";
import TableUsuario from "views/admin/TableUsuario.js";
import TableEmpresas from "views/admin/TableEmpresas.js";
import TableRegistro from "views/admin/TableRegistro.js";
import TableLinea from "views/admin/TableLinea.js";
import AltaInstalacion from "views/admin/AltaInstalacion.js";
import EditarInstalacion from "views/admin/EditarInstalacion.js";
import TableTecnicos from "views/admin/TableTecnicos";
import EditarTecnico from "views/admin/EditarTecnico";
import FormTecnico from "views/admin/FormTecnico";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-56 bg-blueGray-100">
        <AdminNavbar />

        <div className="px-4 md:px-10 mx-auto w-full h-screen flex flex-col gap-4">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/list-user" exact component={TableUsuario} />
            <Route path="/admin/add-user" exact component={FormUsuario} />
            <Route
              path="/admin/edit-user/:id_usuario"
              exact
              component={EditarUsuario}
            />

            <Route
              path="/admin/list-registros"
              exact
              component={TableRegistro}
            />
            <Route
              path="/admin/add-registro"
              exact
              component={AltaInstalacion}
            />
            <Route
              path="/admin/edit-registro/:id_registro"
              exact
              component={EditarInstalacion}
            />

            <Route
              path="/admin/list-empresas"
              exact
              component={TableEmpresas}
            />
            <Route path="/admin/add-empresa" exact component={FormEmpresa} />
            <Route
              path="/admin/edit-empresa/:id_empresa"
              exact
              component={EditarEmpresa}
            />

            <Route path="/admin/list-lineas" exact component={TableLinea} />
            <Route path="/admin/add-linea" exact component={FormLinea} />
            <Route
              path="/admin/edit-linea/:id_linea"
              exact
              component={EditarLinea}
            />

            <Route
              path="/admin/list-tecnicos"
              exact
              component={TableTecnicos}
            />
            <Route path="/admin/add-tecnico" exact component={FormTecnico} />
            <Route
              path="/admin/edit-tecnico/:id_tecnico"
              exact
              component={EditarTecnico}
            />

            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
