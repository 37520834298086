import ReactDotWrapper from "../../components/Dots/ReactDotWrapper";
import { useEffect, useState } from "react";
import axiosClient from "config/axios";
import Alert from "components/Alerts/Alert";
import { useParams } from "react-router-dom";

export default function AltaInstalacion() {
  const { id_registro } = useParams();
  const [empresas, setEmpresas] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [instaladores, setInstaladores] = useState([]);
  const [loadingDatos, setLoadingDatos] = useState(true);
  const [instalador_1, setInstalador1] = useState("");
  const [instalador_2, setInstalador2] = useState("");
  const [selectedEmpresa, setSelectedEmpresa] = useState("");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [puntos, setPuntos] = useState("");
  const [actualizando, setActualizando] = useState(false);

  const [selectedFile, setSelectedFile] = useState([]);
  const [preview, setPreview] = useState([]);

  const [puntosIniciales, setPuntosIniciales] = useState([]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = selectedFile.map((each) => URL.createObjectURL(each));
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {}, [preview]);

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });

  const [data, setData] = useState({
    interno: "",
    patente: "",
    imagenes: [],
    fecha: "",
    fecha_modificacion: "",
    numero_serie_validador: "",
    numero_serie_mk: "",
    numero_serie_teclado: "",
    luz_alta: false,
    luz_posicionamiento: false,
    luz_giro: false,
    luz_tablero: false,
    balizas: false,
    limpia_parabrisas: false,
    bocina: false,
    encendido: false,
    espejos: false,
    parabrisas: false,
    fusilera: false,
    fotos_ig_originales: false,
    mk_orientacion_orificio: false,
    mk_rebarbado_orificio: false,
    mk_antena_gps: false,
    mk_criptado_rj45: false,
    consola_proteccion_cables: false,
    consola_soporte: false,
    consola_cableado_mk: false,
    consola_criptado_rj45: false,
    general_colocacion_gps: false,
    general_tension_power: false,
    general_fotos_instalacion: false,
    general_configuracion: false,
    observaciones: "",
    puntos_imagen: "",
    responsable_empresa_nombre: "",
    responsable_empresa_telefono: "",
    id_tecnicos: "",
  });

  const updateValueInput = (e) => {
    var newDatos = { ...data };
    newDatos[e.target.name] = e.target.value;
    setData(newDatos);
  };
  const updateValueCheckbox = (e) => {
    var newDatos = { ...data };
    newDatos[e.target.name] = e.target.checked;
    setData(newDatos);
  };

  const actualizarDatos = () => {
    if (JSON.stringify(puntos) === "[]") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe marcar el dispositivo en la imagen",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.responsable_empresa_nombre === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar el nombre del responsable de la empresa",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    if (data.responsable_empresa_telefono === "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe ingresar el telefono del responsable de la empresa",
        show: true,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return true;
    }
    setActualizando(true);
    var payload = {
      ...data,
      linea_id: selectedLinea,
      id_tecnicos:
        instalador_1 + (instalador_2 !== "" ? "," + instalador_2 : ""),
      numero_serie_validador: data.numero_serie_validador,
      numero_serie_mk: data.numero_serie_mk,
      numero_serie_teclado: data.numero_serie_teclado,
      puntos_imagen: JSON.stringify(puntos),
      luz_alta: data.luz_alta ? 1 : 0,
      luz_posicionamiento: data.luz_posicionamiento ? 1 : 0,
      luz_giro: data.luz_giro ? 1 : 0,
      luz_tablero: data.luz_tablero ? 1 : 0,
      balizas: data.balizas ? 1 : 0,
      limpia_parabrisas: data.limpia_parabrisas ? 1 : 0,
      bocina: data.bocina ? 1 : 0,
      encendido: data.encendido ? 1 : 0,
      espejos: data.espejos ? 1 : 0,
      parabrisas: data.parabrisas ? 1 : 0,
      fusilera: data.fusilera ? 1 : 0,
      fotos_ig_originales: data.fotos_ig_originales ? 1 : 0,
      mk_orientacion_orificio: data.mk_orientacion_orificio ? 1 : 0,
      mk_rebarbado_orificio: data.mk_rebarbado_orificio ? 1 : 0,
      mk_antena_gps: data.mk_antena_gps ? 1 : 0,
      mk_criptado_rj45: data.mk_criptado_rj45 ? 1 : 0,
      consola_proteccion_cables: data.consola_proteccion_cables ? 1 : 0,
      consola_soporte: data.consola_soporte ? 1 : 0,
      consola_cableado_mk: data.consola_cableado_mk ? 1 : 0,
      consola_criptado_rj45: data.consola_criptado_rj45 ? 1 : 0,
      general_colocacion_gps: data.general_colocacion_gps ? 1 : 0,
      general_tension_power: data.general_tension_power ? 1 : 0,
      general_fotos_instalacion: data.general_fotos_instalacion ? 1 : 0,
      general_configuracion: data.general_configuracion ? 1 : 0,
      empresa_id: data.linea.empresa.id,
    };
    axiosClient
      .put("/registros/" + id_registro, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setActualizando(false);
        setAlert({
          ...alert,
          bgColor: "green-500",
          message: "Registro editado exitosamente",
          show: true,
        });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }

        setAlert({
          ...alert,
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error editar el registro.",
          show: true,
        });
        setActualizando(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };

  useEffect(() => {
    axiosClient("empresas", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setEmpresas(res.data.empresas);
    });
    axiosClient("lineas?empresa_id=" + selectedEmpresa, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setLineas(res.data.lineas);
    });
    axiosClient("tecnicos", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setInstaladores(res.data.tecnicos);
    });
  }, []);

  useEffect(() => {
    axiosClient("lineas?empresa_id=" + selectedEmpresa, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setLineas(res.data.lineas);
    });
  }, [selectedEmpresa]);

  useEffect(() => {
    axiosClient
      .get("registros/" + id_registro, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData({
            ...response.data.registro,
            luz_alta: response.data.registro.luz_alta === "1",
            luz_posicionamiento:
              response.data.registro.luz_posicionamiento === "1",
            luz_giro: response.data.registro.luz_giro === "1",
            luz_tablero: response.data.registro.luz_tablero === "1",
            balizas: response.data.registro.balizas === "1",
            limpia_parabrisas: response.data.registro.limpia_parabrisas === "1",
            bocina: response.data.registro.bocina === "1",
            encendido: response.data.registro.encendido === "1",
            espejos: response.data.registro.espejos === "1",
            parabrisas: response.data.registro.parabrisas === "1",
            fusilera: response.data.registro.fusilera === "1",
            fotos_ig_originales:
              response.data.registro.fotos_ig_originales === "1",
            mk_orientacion_orificio:
              response.data.registro.mk_orientacion_orificio === "1",
            mk_rebarbado_orificio:
              response.data.registro.mk_rebarbado_orificio === "1",
            mk_antena_gps: response.data.registro.mk_antena_gps === "1",
            mk_criptado_rj45: response.data.registro.mk_criptado_rj45 === "1",
            consola_proteccion_cables:
              response.data.registro.consola_proteccion_cables === "1",
            consola_soporte: response.data.registro.consola_soporte === "1",
            consola_cableado_mk:
              response.data.registro.consola_cableado_mk === "1",
            consola_criptado_rj45:
              response.data.registro.consola_criptado_rj45 === "1",
            general_colocacion_gps:
              response.data.registro.general_colocacion_gps === "1",
            general_tension_power:
              response.data.registro.general_tension_power === "1",
            general_fotos_instalacion:
              response.data.registro.general_fotos_instalacion === "1",
            general_configuracion:
              response.data.registro.general_configuracion === "1",
          });
          setPuntosIniciales(JSON.parse(response.data.registro.puntos_imagen));
          setLoadingDatos(false);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      {loadingDatos ? (
        <div className="rounded-lg bg-green-200 border border-gray-900 px-12 py-12 mt-8 w-full flex justify-center items-center">
          <svg className="w-12 h-12 animate-spin" viewBox="0 0 24 24">
            <path
              d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
              opacity=".5"
              fill="currentColor"
            ></path>
            <path
              d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
              fill="currentColor"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 12 12"
                to="360 12 12"
                dur="1s"
                repeatCount="indefinite"
              ></animateTransform>
            </path>
          </svg>
        </div>
      ) : (
        <div
          className={`rounded shadow-lg text-left ${
            alert.show ? "" : "mt-4"
          } border-2 bg-white border-gray-900 `}
        >
          <div className="flex flex-col p-4 ">
            <div className="flex flex-col gap-2 ">
              <div className="flex flex-row justify-between gap-2 ">
                <h2 className="text-2xl font-semibold leading-tight">
                  Editar registro de instalacion
                </h2>
                <h3 className="text-2xl font-normal leading-tight">
                  {data.fecha_editado == data.fecha
                    ? "Sin editar"
                    : " Ultima edicion: " +
                      data.fecha_editado.replace("T", " ")}{" "}
                </h3>
              </div>
              <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0  ">
                <div className="flex flex-col ">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    EMPRESA (NO MODIFICABLE)
                  </label>
                  <select
                    onChange={(e) => setSelectedEmpresa(e.target.value)}
                    value={selectedEmpresa}
                    className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  >
                    <option value={data.linea.empresa.id} default>
                      {data.linea.empresa.nombre}
                    </option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    LINEA (NO MODIFICABLE)
                  </label>
                  <select
                    onChange={(e) => setSelectedLinea(e.target.value)}
                    value={selectedLinea}
                    className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  >
                    <option key={data.linea.id} value={data.linea.id} default>
                      {data.linea.nombre}
                    </option>
                  </select>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0  ">
                <div className="flex flex-col">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    INTERNO (NO MODIFICABLE)
                  </label>
                  <input
                    type="text"
                    name="interno"
                    disabled
                    value={data.interno}
                    placeholder="Ingrese el numero de interno"
                    className="bg-gray-100 w-full rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    PATENTE (NO MODIFICABLE)
                  </label>
                  <input
                    type="text"
                    name="patente"
                    disabled
                    value={data.patente}
                    placeholder="Ingrese la patente del vehiculo"
                    className="bg-gray-100 w-full rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
              </div>
              <div
                className={`lg:grid lg:grid-cols-${
                  data.tecnicos[1] ? "2" : 1
                } gap-6 space-y-4 lg:space-y-0 mt-2 `}
              >
                <div className="flex flex-col">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    INSTALADOR N° 1 (NO MODIFICABLE)
                  </label>
                  <select
                    onChange={(e) => setInstalador1(e.target.value)}
                    value={instalador_1}
                    className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  >
                    <option value={data.tecnicos[0].id} default>
                      {data.tecnicos[0].nombre}
                    </option>
                  </select>
                </div>
                {data.tecnicos[1] ? (
                  <div className="flex flex-col">
                    <label className="text-gray-500 text-xs font-medium mb-1">
                      TECNICO INSTALADOR N° 2 (NO MODIFICABLE)
                    </label>
                    <select
                      onChange={(e) => setInstalador2(e.target.value)}
                      value={instalador_2}
                      className="w-full rounded ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                    >
                      <option value={data.tecnicos[1].id} default>
                        {data.tecnicos[1].nombre}
                      </option>
                    </select>
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="bg-gray-300 px-2 py-2 my-2">
                <div className="flex lg:flex-row flex-col justify-evenly ">
                  <div className="text-center flex flex-col">
                    <label className="text-base text-black font-medium mb-1">
                      Serie Validador
                    </label>

                    <input
                      type="text"
                      name="numero_serie_validador"
                      value={data.numero_serie_validador}
                      onChange={updateValueInput}
                      className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                    />
                  </div>
                  <div className="text-center flex flex-col">
                    <label className="text-black text-base font-medium mb-1">
                      Serie Mounting Kit
                    </label>
                    <input
                      type="text"
                      name="numero_serie_mk"
                      value={data.numero_serie_mk}
                      onChange={updateValueInput}
                      className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                    />
                  </div>
                  <div className="text-center flex flex-col">
                    <label className="text-black text-base font-medium mb-1">
                      Serie Teclado
                    </label>
                    <input
                      type="text"
                      name="numero_serie_teclado"
                      value={data.numero_serie_teclado}
                      onChange={updateValueInput}
                      className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="flex flex-col justify-center items-center">
                <p className="bg-white border border-black text-center w-full lg:w-1/2">
                  CONTROL DE INSTALACIONES (SELECCIONES SI CUMPLE)
                </p>
              </div>
              <div className="flex flex-col lg:flex-row justify-evenly">
                <div className="flex flex-col ">
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="luz_alta"
                      defaultChecked={data.luz_alta}
                    />
                    Luz alta
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="luz_posicionamiento"
                      defaultChecked={data.luz_posicionamiento}
                    />
                    Luz posicionamiento
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="luz_giro"
                      value={data.luz_giro}
                      defaultChecked={data.luz_giro}
                    />
                    Luz giro
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="luz_tablero"
                      defaultChecked={data.luz_tablero}
                    />
                    Luz tablero
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="balizas"
                      defaultChecked={data.balizas}
                    />
                    Balizas
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="limpia_parabrisas"
                      defaultChecked={data.limpia_parabrisas}
                    />
                    Limpia parabrisas
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="bocina"
                      defaultChecked={data.bocina}
                    />
                    Bocina
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="encendido"
                      defaultChecked={data.encendido}
                    />
                    Encendido
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="espejos"
                      defaultChecked={data.espejos}
                    />
                    Espejos
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="parabrisas"
                      defaultChecked={data.parabrisas}
                    />
                    Parabrisas
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="fusilera"
                      defaultChecked={data.fusilera}
                    />
                    Fusilera
                  </label>
                  <label className="text-gray-500 text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="fotos_ig_originales"
                      defaultChecked={data.fotos_ig_originales}
                    />
                    Fotos de IG originales
                  </label>
                </div>
                <div>
                  <ReactDotWrapper
                    puntosIniciales={puntosIniciales}
                    setPuntos={setPuntos}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 my-2 py-2">
              <div className="justify-center items-center flex flex-col">
                <p className="bg-white border border-black text-center w-full lg:w-1/2 ">
                  INSTALACION
                </p>
              </div>
              <div className="bg-gray-300 px-2 py-2">
                <p className="text-xl">MK</p>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="mk_orientacion_orificio"
                      defaultChecked={data.mk_orientacion_orificio}
                    />
                    Orientacion del orificio (salida cables) del Validador
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="mk_rebarbado_orificio"
                      defaultChecked={data.mk_rebarbado_orificio}
                    />
                    Rebarbado del orificio
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="mk_antena_gps"
                      defaultChecked={data.mk_antena_gps}
                    />
                    Correcta fijación de la antena GPS
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="mk_criptado_rj45"
                      defaultChecked={data.mk_criptado_rj45}
                    />
                    Correcto crimpado de RJ45 en MK
                  </label>
                </div>
              </div>
              <div className="bg-gray-300 px-2 py-2">
                <p className="text-xl">CONSOLA</p>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="consola_proteccion_cables"
                      defaultChecked={data.consola_proteccion_cables}
                    />
                    Protección de cables con corrugado y precintos
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="consola_soporte"
                      defaultChecked={data.consola_soporte}
                    />
                    Estado de soporte consola
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="consola_cableado_mk"
                      defaultChecked={data.consola_cableado_mk}
                    />
                    Correcto cableado de MK a consola
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="consola_criptado_rj45"
                      defaultChecked={data.consola_criptado_rj45}
                    />
                    Correcto crimpado de RJ45 en consola
                  </label>
                </div>
              </div>
              <div className="bg-gray-300 px-2 py-2">
                <p className="text-xl">GENERAL</p>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="general_colocacion_gps"
                      defaultChecked={data.general_colocacion_gps}
                    />
                    Colocación de antena GPS en lugar y forma apropiada
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="general_tension_power"
                      defaultChecked={data.general_tension_power}
                    />
                    Toma de tensión cable POWER en lugar apropiado (Fusilera)
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="general_fotos_instalacion"
                      defaultChecked={data.general_fotos_instalacion}
                    />
                    Fotos instalación final
                  </label>
                </div>
                <div>
                  <label className="text-black text-base font-medium mb-1">
                    <input
                      type="checkbox"
                      onChange={updateValueCheckbox}
                      name="general_configuracion"
                      defaultChecked={data.general_configuracion}
                    />
                    Configuración
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-col my-2">
              <label className="text-gray-500 text-xs font-medium mb-1">
                OBSERVACIONES
              </label>
              <textarea
                value={data.observaciones}
                onChange={updateValueInput}
                name="observaciones"
                placeholder="Ingrese observaciones a tener en cuenta"
                className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                rows={3}
              ></textarea>
            </div>
            <hr />
            <div className="flex flex-col my-2">
              <label className="text-gray-500 text-xs font-medium mb-1">
                FOTOS ADJUNTAS
              </label>
              <div>
                <div className="flex flex-row flex-wrap gap-2">
                  {data.imagenes.map((each, index) => (
                    <>
                      <>
                        <img
                          alt="Colectivo imagen"
                          className="w-44 h-44 z-1 -mr-4"
                          src={"data:image/png;base64," + each.file}
                        />
                      </>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p className="text-md font-bold my-2">
                RESPONSABLE EMPRESA DE TRANSPORTE
              </p>
              <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0  ">
                <div className="flex flex-col">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    NOMBRE
                  </label>
                  <input
                    type="text"
                    name="responsable_empresa_nombre"
                    onChange={updateValueInput}
                    value={data.responsable_empresa_nombre}
                    placeholder="Ingrese nombre del responsable"
                    className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
                <div className="flex flex-col my-2">
                  <label className="text-gray-500 text-xs font-medium mb-1">
                    TELEFONO
                  </label>
                  <input
                    type="text"
                    name="responsable_empresa_telefono"
                    onChange={updateValueInput}
                    value={data.responsable_empresa_telefono}
                    placeholder="Ingrese telefono del responsable"
                    className="bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="flex justify-end m-4">
              <button
                onClick={actualizarDatos}
                className={
                  actualizando
                    ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-xl px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-2/12 justify-center lg:mt-1.5"
                    : "bg-green-500 flex flex-row items-center font-medium text-white text-xl px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-2/12 justify-center lg:mt-1.5"
                }
                disabled={actualizando}
              >
                {actualizando ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                    <path
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                      fill="currentColor"
                    ></path>
                  </svg>
                )}
                {actualizando ? "Editando registro" : "Editar registro"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
