import { useState, useContext, useEffect } from "react";
import axiosClient from "../../config/axios";
import Switch from "react-switch";
import Alert from "components/Alerts/Alert";
import AdminContext from "context/admin/adminContext";

function FormEmpresa() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [loadingDatos, setLoadingDatos] = useState(false);
  const [estado, setEstado] = useState(true);
  const [actualizando, setActualizando] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [preview, setPreview] = useState([]);
  const [empresaDatos, setEmpresaDatos] = useState({
    nombre: "",
    mail: "",
    estado: true,
  });
  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...empresaDatos };
    newDatos[e.target.name] = e.target.value;
    setEmpresaDatos(newDatos);
  };

  const onSwitchChange = () => {
    var newDatos = { ...empresaDatos };
    setEstado(!estado);
    newDatos["estado"] = !estado;
    setEmpresaDatos(newDatos);
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile([]);
      return;
    }
    setSelectedFile([e.target.files[0]]);
  };

  const sendFile = (id_empresa) => {
    var formData = new FormData();
    formData.append("file", selectedFile[0]);
    axiosClient
      .post("empresas/firma_digital/" + id_empresa, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  };

  useEffect(() => {
    if (!selectedFile.length > 0) {
      setPreview([]);
      return;
    }
    const objectUrl = selectedFile.map((each) => URL.createObjectURL(each));
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const actualizarDatos = () => {
    if (empresaDatos.nombre == "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe cargar ingresar un nombre",
        show: true,
      });
      return true;
    }

    if (empresaDatos.mail == "") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe cargar ingresar un mail",
        show: true,
      });
      return true;
    }

    if (selectedFile.length == 0) {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe cargar una firma digital",
        show: true,
      });
      return true;
    }
    setActualizando(true);
    // Crea la empresa
    var payload = {
      nombre: empresaDatos.nombre,
      activo: empresaDatos.estado,
      mail: empresaDatos.mail,
    };
    axiosClient
      .post("empresas/crear", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.code === 201) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Empresa creada exitosamente",
            show: true,
          });
          setEmpresaDatos({
            nombre: "",
            mail: "",
            estado: true,
          });
          setSelectedFile([]);
          setPreview([]);
          // Crea la firma digital
          if (selectedFile.length > 0) {
            sendFile(response.data.empresa.id);
          }
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message: "Hubo un error creando la empresa, intentá más tarde",
            show: true,
          });
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }

        setAlert({
          ...alert,
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error creando la empresa, intentá más tarde",
          show: true,
        });
        setActualizando(false);
      });
  };

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Agregar Empresa
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés crear un nuevo empresa para luego poder asignarle los cursos
          correspondientes
        </p>
        <div className="lg:grid lg:grid-cols-3 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE
            </label>
            <input
              name="nombre"
              value={empresaDatos.nombre}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              MAIL
            </label>
            <input
              name="mail"
              value={empresaDatos.mail}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Mail"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ACTIVO
            </label>
            <Switch
              name="estado"
              onChange={onSwitchChange}
              checked={empresaDatos.estado}
            />
          </div>
        </div>

        <div className="lg:grid lg:grid-cols-1 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              FIRMA DIGITAL EN BLANCO Y NEGRO (OBLIGATORIO)
            </label>
            <div>
              <input
                type="file"
                onChange={onSelectFile}
                id="selectedFile"
                accept="image/*"
                className="hidden"
              />
              <input
                type="button"
                value="Subir foto"
                className=" p-1 px-3 mt-1 mb-1 hover:bg-gray-300"
                onClick={() => document.getElementById("selectedFile").click()}
              />
              <div className="flex flex-row flex-wrap gap-2">
                {selectedFile &&
                  preview.map((each, index) => (
                    <>
                      <img className="w-44 h-44 z-1 -mr-4" src={each} />
                      {/* <svg
                        className=" z-10 cursor-pointer"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                        onClick={() => setSelectedFile([])}
                      >
                        <path
                          fill="#FF0000"
                          d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"
                        ></path>
                      </svg> */}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarDatos();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className={
              habilitarEdicion
                ? actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
                : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
            }
            disabled={actualizando}
          >
            {habilitarEdicion ? (
              actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizando
                  ? "Actualizando"
                  : "Crear empresa"
                : "Editar"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}

export default FormEmpresa;
