import { useState, useEffect, useContext } from "react";
import axiosClient from "../../config/axios";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import AdminContext from "context/admin/adminContext";

function EditarLinea() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);
  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [estado, setEstado] = useState(true);
  const [empresa, setEmpresa] = useState("0");
  const [empresas, setEmpresas] = useState([]);
  const [actualizando, setActualizando] = useState(false);
  const [lineaDatos, setLineaDatos] = useState({
    nombre: "",
    activo: "True",
  });

  const { id_linea } = useParams();

  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...lineaDatos };
    newDatos[e.target.name] = e.target.value;
    setLineaDatos(newDatos);
  };

  const onSwitchChange = () => {
    var newDatos = { ...lineaDatos };
    setEstado(!estado);
    newDatos["activo"] = !estado;
    setLineaDatos(newDatos);
  };

  const updateSelect = (value) => {
    setEmpresa(value);
  };

  const actualizarDatos = () => {
    if (empresa == "0") {
      setAlert({
        ...alert,
        bgColor: "red-500",
        message: "Debe seleccionar una empresa",
        show: true,
      });
      return;
    }

    setActualizando(true);
    var payload = {
      nombre: lineaDatos.nombre,
      activo: lineaDatos.activo,
      empresa_id: empresa,
    };

    axiosClient
      .put("lineas/" + id_linea, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Linea modificada exitosamente!",
            show: true,
          });
          //setHabilitarEdicion(false);
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message: "Hubo un error editando la linea, intentá más tarde",
            show: true,
          });
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
        setAlert({
          ...alert,
          bgColor: "red-500",
          message: "Hubo un error editando la linea, intentá más tarde",
          show: true,
        });
        setActualizando(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("lineas/" + id_linea, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLineaDatos(response.data.linea);
          setEstado(response.data.linea.activo === "True" ? true : false);
          setEmpresa(response.data.linea.empresa_id);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  useEffect(() => {
    axiosClient
      .get("empresas", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setEmpresas(response.data.empresas);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }
      });
  }, []);

  return (
    <>
      {alert.show ? (
        <div
          className={`bg-${alert.bgColor} mt-4 rounded text-white text-lg px-4 py-2 shadow-lg font-bold flex flex-col lg:flex-row justify-between items-center`}
        >
          <div className="flex flex-row items-center gap-2">
            {alert.bgColor.includes("red") ? (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm-8 56a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12z"
                  fill="currentColor"
                ></path>
              </svg>
            ) : (
              <svg className="w-6 h-6" viewBox="0 0 256 256">
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.12 104.12 0 0 0 128 24zm49.531 85.79l-58.672 56a8.024 8.024 0 0 1-11.062 0l-29.328-28a8.007 8.007 0 0 1 11.062-11.58l23.797 22.728l53.14-50.727a8.007 8.007 0 0 1 11.063 11.578z"
                  fill="currentColor"
                ></path>
              </svg>
            )}

            {alert.message}
          </div>
          <button
            className="bg-white px-2 py-0.5 font-bold rounded text-gray-600 hover:bg-gray-300 transition duration-300"
            onClick={() => {
              setAlert({ ...alert, show: false });
            }}
          >
            X
          </button>
        </div>
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Editar Linea
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés modificar una linea ya creada
        </p>
        <div className="lg:grid lg:grid-cols-3 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-2">
              NOMBRE
            </label>
            <input
              name="nombre"
              value={lineaDatos.nombre}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder="Nombre"
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              EMPPRESA
            </label>
            <select
              name="empresa"
              value={empresa}
              onChange={(e) => updateSelect(e.target.value)}
              className=" rounded m-1  ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
            >
              {empresas.map((e) => (
                <option
                  key={e.id}
                  value={e.id}
                  selected={empresa == e.id ? true : false}
                >
                  {e.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ACTIVO
            </label>
            <Switch name="estado" onChange={onSwitchChange} checked={estado} />
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarDatos();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className={
              habilitarEdicion
                ? actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
                : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
            }
            disabled={actualizando}
          >
            {habilitarEdicion ? (
              actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizando
                  ? "Actualizando"
                  : "Guardar Cambios"
                : "Editar"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}

export default EditarLinea;
