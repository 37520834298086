export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";

export const LOGING_SUCCESS = "LOGING_SUCCESS";
export const LOGING_ERROR = "LOGING_ERROR";
export const GET_USER = "GET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_FECHA = "SET_FECHA";
export const MODAL_BAJA_CATE = "MODAL_BAJA_CATE";
export const MODAL_BAJA_CURSO = "MODAL_BAJA_CURSO";
export const MODAL_BAJA_PROG = "MODAL_BAJA_PROG";
export const MODAL_BAJA_PROFE = "MODAL_BAJA_PROFE";
export const SET_FLAG_JWT = "SET_FLAG_JWT";
