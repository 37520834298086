import axiosClient from "config/axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Alert from "components/Alerts/Alert";
import jwt from "jwt-decode";

export default function Login() {
  const [datosLogin, setDatosLogin] = useState({
    usuario: "",
    password: "",
  });
  const [datosAlert, setDatosAlert] = useState({
    bgColor: "red-500",
    message: "Credenciales inválidas",
    show: false,
  });
  const [loadingLogin, setLoadingLogin] = useState(false);

  let history = useHistory();

  const login = () => {
    if (datosLogin.usuario === "" || datosLogin.password === "") {
      setDatosAlert({
        bgColor: "red-500",
        message: "Por favor, completá ambos campos",
        show: true,
      });
      return false;
    }
    setLoadingLogin(true);
    var bodyFormData = new FormData();
    bodyFormData.append("username", datosLogin.usuario);
    bodyFormData.append("password", datosLogin.password);

    axiosClient
      .post("login", bodyFormData)
      .then((response) => {
        setLoadingLogin(false);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("username", datosLogin.usuario);
        const user = jwt(response.data.access_token);
        localStorage.setItem("role", user.role);
        localStorage.setItem("user_id", user.user_id);
        if (user.role == "True") {
          history.push("/admin/dashboard");
        } else {
          history.push("/admin/list-registros");
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          setDatosAlert({
            bgColor: "red-500",
            message: "Credenciales inválidas",
            show: true,
          });
          setLoadingLogin(false);
        } else {
          setDatosAlert({
            bgColor: "red-500",
            message: "Ocurrió un error inesperado, intentá más tarde",
            show: true,
          });
          setLoadingLogin(false);
        }
      });
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              {datosAlert.show ? (
                <div className="p-4">
                  {" "}
                  <Alert
                    bgColor={datosAlert.bgColor}
                    message={datosAlert.message}
                    funcionCierre={() =>
                      setDatosAlert({ ...datosAlert, show: false })
                    }
                  />
                </div>
              ) : null}
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-600 text-center mb-3 mt-3 font-bold">
                  <small>Ingresá tus credenciales</small>
                </div>
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Usuario
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Usuario"
                      value={datosLogin.usuario}
                      onChange={(e) =>
                        setDatosLogin({
                          ...datosLogin,
                          usuario: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Contraseña
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Contraseña"
                      value={datosLogin.password}
                      onChange={(e) =>
                        setDatosLogin({
                          ...datosLogin,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 h-10 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 flex justify-center items-center"
                      onClick={(e) => {
                        e.preventDefault();
                        login();
                      }}
                      type="submit"
                    >
                      {!loadingLogin ? (
                        "Iniciar Sesión"
                      ) : (
                        <svg
                          className="w-8 h-8 animate-spin text-white"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
                            opacity=".5"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M20 12h2A10 10 0 0 0 12 2v2a8 8 0 0 1 8 8z"
                            fill="currentColor"
                          >
                            <animateTransform
                              attributeName="transform"
                              type="rotate"
                              from="0 12 12"
                              to="360 12 12"
                              dur="1s"
                              repeatCount="indefinite"
                            ></animateTransform>
                          </path>
                        </svg>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
