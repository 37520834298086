import { useState, useContext, useEffect } from "react";
import axiosClient from "../../config/axios";
import Switch from "react-switch";
import Alert from "components/Alerts/Alert";
import AdminContext from "context/admin/adminContext";

function FormTecnico() {
  const adminContext = useContext(AdminContext);
  const { checkJWT } = adminContext;

  useEffect(() => {
    checkJWT(window.location.pathname);
  }, []);

  const [habilitarEdicion, setHabilitarEdicion] = useState(true);
  const [loadingDatos, setLoadingDatos] = useState(false);
  const [estado, setEstado] = useState(true);
  const [actualizando, setActualizando] = useState(false);
  const [tecnicoDatos, setTecnicoDatos] = useState({
    nombre: "",
    estado: true,
  });
  const [alert, setAlert] = useState({
    bgColor: "red-500",
    message: "Hubo un error...",
    show: false,
  });
  const updateValue = (e) => {
    var newDatos = { ...tecnicoDatos };
    newDatos[e.target.name] = e.target.value;
    setTecnicoDatos(newDatos);
  };

  const onSwitchChange = () => {
    var newDatos = { ...tecnicoDatos };
    setEstado(!estado);
    newDatos["estado"] = !estado;
    setTecnicoDatos(newDatos);
  };

  const actualizarDatos = () => {
    setActualizando(true);
    var payload = {
      nombre: tecnicoDatos.nombre,
      activo: tecnicoDatos.estado,
    };
    axiosClient
      .post("tecnicos/crear", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.code === 201) {
          setAlert({
            ...alert,
            bgColor: "green-500",
            message: "Tecnico creado exitosamente",
            show: true,
          });
          setTecnicoDatos({
            nombre: "",
            estado: true,
          });
        } else {
          setAlert({
            ...alert,
            bgColor: "red-500",
            message: "Hubo un error creando el tecnico, intentá más tarde",
            show: true,
          });
        }
        setActualizando(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.code === 401) {
          window.location.href = "/auth/login";
        }

        setAlert({
          ...alert,
          bgColor: "red-500",
          message: err.response.data.error
            ? err.response.data.error
            : "Hubo un error creando el tecnico, intentá más tarde",
          show: true,
        });
        setActualizando(false);
      });
  };

  return (
    <>
      {alert.show ? (
        <Alert
          bgColor={alert.bgColor}
          message={alert.message}
          funcionCierre={() => {
            setAlert({ ...alert, show: false });
          }}
        />
      ) : null}
      <div className="bg-white p-4 rounded-lg border-2 border-gray-900 shadow-xl mt-4">
        <h1 className="text-gray-900 text-2xl font-semibold mb-2 mt-2">
          Agregar Tecnico
        </h1>
        <p className="mb-12 text-gray-600">
          Acá podés crear un nuevo tecnico para luego poder asignarle los cursos
          correspondientes
        </p>
        <div className="lg:grid lg:grid-cols-2 gap-6 space-y-4 lg:space-y-0 mt-4 mb-4">
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              NOMBRE
            </label>
            <input
              name="nombre"
              value={tecnicoDatos.nombre}
              className={
                habilitarEdicion
                  ? "bg-gray-100 rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none"
                  : "bg-gray-200 cursor-not-allowed rounded px-2 py-2 ring-1 ring-indigo-200 focus:ring-2 focus:ring-indigo-300 focus:outline-none text-gray-600"
              }
              placeholder={loadingDatos ? "Cargando..." : "Nombre"}
              disabled={!habilitarEdicion}
              onChange={(e) => updateValue(e)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-xs font-medium mb-1">
              ACTIVO
            </label>
            <Switch
              name="estado"
              onChange={onSwitchChange}
              checked={tecnicoDatos.estado}
            />
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            onClick={() => {
              if (habilitarEdicion) {
                actualizarDatos();
              } else {
                setHabilitarEdicion(!habilitarEdicion);
              }
            }}
            className={
              habilitarEdicion
                ? actualizando
                  ? "bg-gray-400 flex flex-row items-center font-medium text-gray-800 text-lg px-6 py-1 rounded-md focus:outline-none cursor-not-allowed w-full lg:w-3/12 2xl:w-2/12 justify-center lg:mt-1.5"
                  : "bg-green-500 flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-green-600 w-full lg:w-3/12 2xl:w-3/12 justify-center lg:mt-1.5"
                : "bg-mainBlueLight flex flex-row items-center font-medium text-white text-lg px-6 py-1 rounded-md focus:outline-none transition duration-300 hover:bg-indigo-600 w-full lg:w-2/12 2xl:w-1/12 justify-center lg:mt-1.5"
            }
            disabled={actualizando}
          >
            {habilitarEdicion ? (
              actualizando ? (
                <svg
                  className="animate-spin h-5 w-5 mr-2 mb-0.5 text-mainBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
                  <path
                    d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m-2 15l-5-5l1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
                    fill="currentColor"
                  ></path>
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-1 mb-0.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            )}
            <p className="mb-0.5">
              {" "}
              {habilitarEdicion
                ? actualizando
                  ? "Actualizando"
                  : "Crear tecnico"
                : "Editar"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}

export default FormTecnico;
