import React from "react";
import PropTypes from "prop-types";

// components

export default function CardTable({ color }) {
  return (
    <div>
      <div
        className={
          "relative flex flex-col min-w-0 mt-6 break-words w-full shadow-lg rounded border-gray-900 border-2 py-4 " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-start">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-center">
              <h3
                className={
                  "font-semibold text-2xl text-center" +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                ¡Hola, Admin! Bienvenido al Panel de Administración
              </h3>
              <div className="flex justify-center mt-4 mb-4">
                <p className="font-medium text-lg text-center w-3/4">
                  {" "}
                  En este Panel de Administración se proveen consideraciones a
                  tener en cuenta en el uso del sistema. A futuro podrian
                  visualizarse datos generales o lo que sea necesario
                </p>
              </div>
              <hr />
              <div className="flex mt-4">
                <p className="font-medium text-lg text-center">
                  {" "}
                  - Las empresas tienen asociadas varias lineas, pero cada linea
                  esta asociada a una empresa.
                </p>
              </div>

              <div className="flex mt-4">
                <p className="font-medium text-lg text-center">
                  {" "}
                  - Los operadores podran editar los registros creados por
                  ellos, pero no eliminarlos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
